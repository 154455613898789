import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import custom from "./modules/custom";
import tools from "./modules/tools";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login/login.vue"),
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login/agreement.vue"),
  },
  {
    path: "/wxlogin",
    name: "wxlogin",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login/wxlogin.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login/register.vue"),
  },
  {
    path: "/bindWechat",
    name: "bindWechat",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login/bindWechat.vue"),
  },
  {
    path: "/home",
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
    redirect: "/index",
    children: [
      {
        path: "/index",
        name: "index",
        component: () =>
          import(/* webpackChunkName: "Home" */ "../views/index/index.vue"),
        meta: {
          isNav: true,
        },
      },
      {
        path: "/order",
        name: "order",

        component: () =>
          import(/* webpackChunkName: "Order" */ "../views/order/order.vue"),
        meta: {
          keepAlive: true,
          isBack: false,
          isNav: true,
        },
      },
      {
        path: "/orderDetails",
        name: "orderDetails",
        component: () =>
          import(
            /* webpackChunkName: "Order" */
            "../views/order/orderDetails.vue"
          ),
      },
      {
        path: "/merchant",
        name: "merchant",
        component: () =>
          import(
            /* webpackChunkName: "Merchant" */
            "../views/merchant/merchant.vue"
          ),
        meta: {
          isNav: true,
        },
      },
      // {
      // 	path: "/merchantRecharge",
      // 	name: 'merchantRecharge',
      // 	component: () =>
      // 		import(
      // 			/* webpackChunkName: "Merchant" */
      // 			"../views/merchant/merchantRecharge.vue"
      // 		),
      // },
      {
        path: "/client-Task",
        name: "client-Task",
        component: () =>
          import(
            /* webpackChunkName: "Home" */
            "../views/merchant/client-task.vue"
          ),
        meta: {
          keepAlive: true,
          isBack: false,
          isNav: true,
        },
      },
      {
        path: "/merchantClipDetails",
        name: "merchantClipDetails",
        component: () =>
          import(
            /* webpackChunkName: "Merchant" */
            "../views/merchant/merchantClipDetails.vue"
          ),
      },
      {
        path: "/recharge",
        name: "recharge",
        component: () =>
          import(/* webpackChunkName: "Merchant" */ "../views/Recharge.vue"),
      },

      {
        path: "/assets",
        name: "assets",
        component: () =>
          import(/* webpackChunkName: "Assets" */ "../views/assets/assets.vue"),
        meta: {
          isNav: true,
        },
      },
      {
        path: "/refund",
        name: "refund",
        component: () =>
          import(/* webpackChunkName: "Assets" */ "../views/assets/refund.vue"),
      },
      {
        path: "/recovery",
        name: "recovery",
        component: () =>
          import(
            /* webpackChunkName: "Assets" */
            "../views/assets/recovery.vue"
          ),
      },
      {
        path: "/toBeRecorded",
        name: "toBeRecorded",
        component: () =>
          import(
            /* webpackChunkName: "Assets" */
            "../views/assets/toBeRecorded.vue"
          ),
      },
      {
        path: "/incomeTransferOut",
        name: "incomeTransferOut",
        component: () =>
          import(
            /* webpackChunkName: "Assets" */
            "../views/assets/incomeTransferOut.vue"
          ),
      },
      {
        path: "/withdraw",
        name: "withdraw",
        component: () =>
          import(
            /* webpackChunkName: "Assets" */
            "../views/assets/withdraw.vue"
          ),
      },
      {
        path: "/account",
        name: "account",
        component: () =>
          import(
            /* webpackChunkName: "Account" */
            "../views/account/account.vue"
          ),
        meta: {
          isNav: true,
        },
      },
      {
        path: "/subaccount",
        component: () =>
          import(
            /* webpackChunkName: "Account" */
            "../views/account/subAccount.vue"
          ),
        name: "subAccount",
      },
      {
        path: "/role",
        component: () =>
          import(
            /* webpackChunkName: "Account" */
            "../views/account/role.vue"
          ),
        name: "role",
      },
      /** 剪辑 */
      {
        path: "/clip",
        name: "clip",
        component: () =>
          import(/* webpackChunkName: "clip" */ "../views/task/clip/clip.vue"),
        meta: {
          keepAlive: true,
          isBack: false,
          isNav: true,
        },
      },
      {
        path: "/clipAdd",
        name: "clipAdd",
        component: () =>
          import(
            /* webpackChunkName: "clip" */
            // "../views/task/clip/clipAdd.vue"
            "../views/task/clip/clipAdd/index.vue"
          ),
      },
      {
        path: "/clipPay",
        name: "clipPay",
        component: () =>
          import(
            /* webpackChunkName: "clip" */
            "../views/task/clip/clipPay.vue"
          ),
      },
      {
        path: "/payClip",
        name: "payClip",
        component: () =>
          import(
            /* webpackChunkName: "clip" */
            "../views/task/clip/payClip.vue"
          ),
      },
      {
        path: "/clipDetails",
        name: "clipDetails",
        component: () =>
          import(
            /* webpackChunkName: "clip" */
            "../views/task/clip/clipDetails.vue"
          ),

        // component: () =>
        //   import(
        //     /* webpackChunkName: "clip" */
        //     "../views/task/clip/clipDetail"
        //   ),
      },
      {
        path: "/payment",
        name: "payment",
        component: () =>
          import(
            /* webpackChunkName: "clip" */
            "../views/task/clip/payment.vue"
          ),
      },
      {
        path: "/partTime",
        name: "partTime",
        component: () =>
          import(
            /* webpackChunkName: "partTime" */
            "../views/partTime/partTime.vue"
          ),
        meta: {
          isNav: true,
        },
      },
      {
        path: "/resetPassword",
        name: "resetPassword",
        component: () =>
          import(
            /* webpackChunkName: "login" */
            "../views/login/resetPassword.vue"
          ),
        meta: {
          isFull: false,
        },
      },
      {
        path: "/paymentResults",
        name: "paymentResults",
        component: () =>
          import(/* webpackChunkName: "Order" */ "../views/paymentResults.vue"),
        meta: {
          isFull: false,
        },
      },
      {
        path: "/feedback",
        name: "feedback",
        component: () =>
          import(
            /* webpackChunkName: "Feedback" */
            "../views/feedback/feedback.vue"
          ),
        meta: {
          isFull: false,
        },
      },
      {
        path: "/message",
        name: "message",
        component: () =>
          import(
            /* webpackChunkName: "Message" */
            "../views/message/message.vue"
          ),
        meta: {
          isFull: false,
        },
      },
      {
        path: "/pcHome",
        name: "pcHome",
        component: () =>
          import(
            /* webpackChunkName: "Message" */
            "../views/pcPages/pcHome.vue"
          ),
        name: "userWorskPreview",
        meta: {
          isFull: false,
        },
      },
      {
        path: "/pcViedo",
        name: "pcViedo",
        component: () =>
          import(
            /* webpackChunkName: "Message" */
            "../views/pcPages/pcViedo.vue"
          ),
        meta: {
          isFull: false,
        },
      },
      {
        path: "/customerDemand",
        name: "customerDemand",
        component: () =>
          import(
            /* webpackChunkName: "Message" */
            "../views/merchant/customerDemand"
          ),
        meta: {
          keepAlive: true,
          isBack: false,
          isNav: true,
        },
      },
      {
        path: "/customerDemandDetails",
        name: "customerDemandDetails",
        component: () =>
          import(
            /* webpackChunkName: "Message" */
            "../views/merchant/customerDemand/details.vue"
          ),
      },
      // 互助任务
      {
        path: "/HelpEachOther",
        name: "HelpEachOther",
        component: () =>
          import(
            /* webpackChunkName: "HelpEachOther" */
            "../views/task/HelpEachOther"
          ),
      },
      {
        path: "/HelpEachOtherAdd",
        name: "HelpEachOtherAdd",
        component: () =>
          import(
            /* webpackChunkName: "HelpEachOther" */
            "../views/task/HelpEachOther/add.vue"
          ),
      },
      // 文章任务
      {
        path: "/TheArticle",
        name: "TheArticle",
        component: () =>
          import(
            /* webpackChunkName: "TheArticle" */
            "../views/task/article/TheArticle.vue"
          ),
      },
      ...custom,
      ...tools,
    ],
  },
  {
    path: "/empty",
    name: "empty",
    component: () =>
      import(/* webpackChunkName: "Empty" */ "../views/empty/empty.vue"),
  },
  {
    path: "/extractVideo",
    name: "extractVideo",
    component: () => import("@/views/extractVideo"),
    meta: {
      liqTitle: "视频分享",
    },
  },
  {
    path: "/extractContent",
    name: "extractContent",
    component: () => import("@/views/extractVideo/extractContent"),
    meta: {
      liqTitle: "视频分享",
    },
  },
  {
    path: "/guide",
    name: "guide",
    component: () => import("@/views/merchant/guide"),
    meta: {
      liqTitle: "新人指南",
    },
  },
];

const router = new VueRouter({
  mode: "history", // 去掉#，需要路由模式改为history
  base: "/business/", //设置 base 路径
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.name || to.name == "notfount") {
    router.push("empty");
  }
  /*  if (!store.state.is_open_socket) {
    store.dispatch("socket_init");
  } */

  // 让页面回到顶部
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;

  if (
    from.path.indexOf("Detail") !== -1 ||
    from.path.indexOf("customInfo") !== -1 ||
    from.path.indexOf("editCustom") !== -1
  ) {
    to.meta.isBack = true;
  } else {
    to.meta.isBack = false;
  }

  //修改单个页面浏览器标题
  if (to.meta && to.meta.liqTitle) {
    document.title = to.meta.liqTitle;
  }

  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next();
});

export default router;
